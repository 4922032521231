import React, { useState, useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import { Link } from "gatsby";
import Masonry from "react-masonry-component";

import { Section, Title, Text } from "../../components/Core";
import WorkCard from "../../components/WorkCard";
import { marketPageImages } from "../../data";

const Works = () => {
  const [items, setItems] = useState([]);


  useEffect(() => {
    setItems(marketPageImages);
  }, []);

  const masonryOptions = {
    transitionDuration: 1000,
  };

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative">
        <Container fluid>
          <Masonry
            options={masonryOptions}
            className={"masonry-grid row"} // default ''
          >
            {items.map((item, index) => (
              <Col lg="3" md="4" sm="6" key={index} className="filtr-item">
                <Title variant="cardLg">
                  <Link to={item.link}>{item.title} </Link>
                </Title>
                <WorkCard workItem={item} mb="30px" link={item.link} />
                <Text variant="p" mb="0.5rem">
                  {item.text}
                </Text>
              </Col>
            ))}
          </Masonry>
        </Container>
      </Section>
    </>
  );
};

export default Works;
